import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Observable, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const expectedRoles = route.data['expectedRoles'];

    // Wait for MSAL to be done with any interaction (like redirect) before checking roles
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      take(1), 
      switchMap(() => {
        const account = this.authService.instance.getActiveAccount();

        if (!account) {
          console.error("No active account, redirecting to login.");
          // Redirection to login in case of no active account
          this.authService.loginRedirect({ prompt: 'select_account' } as RedirectRequest);
          return of(false); 
        }

        const roles = account.idTokenClaims?.roles || [];
        if (!this.hasExpectedRoles(roles, expectedRoles)) {
          console.error("User does not have the required roles, redirecting to Unauthorized.");
          this.router.navigate(['/unauthorized']);
          return of(false); 
        }

        return of(true); 
      })
    );
  }

  private hasExpectedRoles(userRoles: string[], expectedRoles: string | string[]): boolean {
    const lowerUserRoles = userRoles.map(role => role.toLowerCase());
    if (Array.isArray(expectedRoles)) {
      return expectedRoles.some(role => lowerUserRoles.includes(role.toLowerCase()));
    }
    return lowerUserRoles.includes(expectedRoles.toLowerCase());
  }
}
