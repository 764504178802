import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from './role-guard.service';
import { UnauthorizedComponent } from './errors/unauthorized/unauthorized.component'; 
import { NotFoundComponent } from './errors/not-found/not-found.component'; 

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [MsalGuard],
    data: { expectedRoles: ['admin', 'miembro-cnbvra'] } 
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule),
    canActivate: [MsalGuard, RoleGuardService],
    data: { expectedRoles: ['admin', 'modulo-formularios'] } 
  },
  {
    path: 'legajos-digitales',
    loadChildren: () => import('./legajos-digitales/legajos-digitales.module').then(m => m.LegajosDigitalesModule),
    canActivate: [MsalGuard, RoleGuardService],
    data: { expectedRoles: ['admin', 'modulo-legajos-digitales'] } 
  },
  {
    path: 'recepcion',
    loadChildren: () => import('./recepcion/recepcion.module').then(m => m.RecepcionModule),
    canActivate: [MsalGuard, RoleGuardService],
    data: { expectedRoles: ['admin', 'modulo-recepcion'] } 
  },
  {
    path: 'compras-comunitarias',
    loadChildren: () => import('./compras-comunitarias/compras-comunitarias.module').then(m => m.ComprasComunitariasModule),
    canActivate: [MsalGuard, RoleGuardService],
    data: { expectedRoles: ['admin', 'modulo-compras-comunitarias'] } 
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent, 
  },
  {
    path: 'not-found',
    component: NotFoundComponent, 
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'not-found' }, 
];

const config: ExtraOptions = {
  useHash: true,
  initialNavigation: 'enabledNonBlocking'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
