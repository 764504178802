<div class="card danger">
    <div class="card-header">
        <span class="icon">⚠️</span> Acceso Denegado
    </div>
    <div class="card-body">
        <p>No tienes permisos suficientes para acceder a esta sección.</p>
        <p>Por favor, intenta con otra cuenta que tenga los permisos adecuados.</p>
    </div>
    <div class="card-footer">
        <button class="btn primary" (click)="onChangeAccount()">Cambiar de cuenta</button>
    </div>
</div>