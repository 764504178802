import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import '@angular/common/locales/global/es-AR';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbSidebarModule, NbMenuModule, NbToastrModule, NbDatepickerModule, NbTimepickerModule, NbWindowModule, NbDialogModule } from '@nebular/theme';

import { RoleProvider } from './role.provider';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { authSettings } from './access.settings';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';
import { UserServices } from './core/user/services/user.services';

import { NbDateFnsDateModule } from '@nebular/date-fns';

import { environment } from '../environments/environment';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { UnauthorizedComponent } from './errors/unauthorized/unauthorized.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { MsalService } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function initializeApp(msalService: MsalService) {
    return () => msalService.instance.initialize().then(async () => {
        try {
            const result = await msalService.instance.handleRedirectPromise();
            if (result !== null && result.account !== null) {
                msalService.instance.setActiveAccount(result.account);
            }
        } catch (error) {
            console.error('Error during redirect login:', error);
        }
    });
}

@NgModule({
    declarations: [
        AppComponent,
        UnauthorizedComponent,
        NotFoundComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.azure.auth.clientId,
                redirectUri: environment.azure.auth.redirectUri, // Verifica este valor en Azure
                authority: 'https://login.microsoftonline.com/' + environment.azure.auth.tenantId,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE, // Set to true for IE 11
            }
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: ['user.read'],
                extraQueryParameters: { "ui_locales": "en-US" }
            }
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                [environment.apiUrl, [environment.azure.apiScope]]
            ])
        }),
        NbThemeModule.forRoot({ name: 'cnbvra-theme' }),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbWindowModule.forRoot(),
        NbDialogModule.forRoot(),
        NbTimepickerModule.forRoot(),
        NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),
        NbToastrModule.forRoot(),
        NbSecurityModule.forRoot({
            accessControl: authSettings,
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [MsalService],
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        UserServices,
        {
            provide: NbRoleProvider,
            useClass: RoleProvider
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule { }
