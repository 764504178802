export const environment = {
  production: true,
  apiUrl: 'https://api-apps.bomberosra.org:443/api',
  azure: {
    auth: {
      clientId: "39797571-49e0-4728-82cd-83762b364aaf",
      tenantId: "dff16913-7f1d-47de-a9f5-e4a42edd9db7",
      redirectUri: "https://apps.bomberosra.org",
      postLogoutRedirectUri: 'https://apps.bomberosra.org',
    },
    apiScope: 'api://fb9f837f-8fb6-45d1-846b-b4d38d067e03/api.scope'
  },
};