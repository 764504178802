import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NbRoleProvider } from '@nebular/security';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private isAuthenticated$ = new BehaviorSubject<boolean>(false); // Para manejar el estado de autenticación

    constructor(
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private roleProvider: NbRoleProvider,
        private router: Router
    ) {
        this.initializeAuthState(); // Iniciar la validación del estado de autenticación
    }

    // Método para inicializar la autenticación y escuchar cambios en el estado de login
    private initializeAuthState(): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status) => status === 'none'),
                take(1)
            )
            .subscribe(() => {
                const account = this.msalService.instance.getActiveAccount();
                this.isAuthenticated$.next(!!account);
            });
    }

    // Método para obtener el estado de autenticación
    getAuthStatus(): Observable<boolean> {
        return this.isAuthenticated$.asObservable();
    }

    // Método para iniciar sesión
    login(): void {
        const loginRequest: RedirectRequest = {
            scopes: ['user.read'],
        };

        this.msalService.loginRedirect(loginRequest);
    }

    // Método para forzar la renovación del token
    renewToken(): void {
        const account = this.msalService.instance.getActiveAccount();
        if (account) {
            const request: SilentRequest = {
                scopes: ['user.read'],
                account: account,
                forceRefresh: true,
            };

            this.msalService.acquireTokenSilent(request).subscribe({
                next: (result) => {
                },
                error: (error) => {
                    console.error('Error renovando token:', error);
                    this.login(); // Si falla, se redirige al login
                },
            });
        }
    }

    // Método para cerrar sesión
    logout(): void {
        this.msalService.logoutRedirect();
    }

    // Método para manejar el cambio de cuenta
    switchAccount(): void {
        localStorage.clear();
        sessionStorage.clear();
        this.msalService.instance.setActiveAccount(null);
        this.msalService.loginRedirect({
            prompt: "select_account"
        } as RedirectRequest);
    }

    // Método para obtener los roles del usuario
    getUserRoles(): Observable<string[]> {
        return this.roleProvider.getRole().pipe(
            map(roles => Array.isArray(roles) ? roles : [roles])
          );
    }

    // Redirigir al home o a otra ruta después del login
    redirectAfterLogin(): void {
        this.router.navigate(['/home']);
    }
}
