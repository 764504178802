import { NbRoleProvider } from '@nebular/security';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

interface Account extends AccountInfo {
    idTokenClaims?: {
      roles?: string[]
    }
  }

@Injectable()
export class RoleProvider extends NbRoleProvider {

  constructor(private authService: MsalService) {
    super();
  }

  getLowerCaseRoles(roles: any): string | string[] {
    if (Array.isArray(roles)) {
      roles = roles.map(element => {
        return element.toLowerCase();
      });
    } else {
      roles = roles.toLowerCase();
    }
    return roles;
  }

  getRole(): Observable<string | string[]> {
    let account: Account = this.authService.instance.getAllAccounts()[0];

    let roles = of(account.idTokenClaims?.roles ? account.idTokenClaims?.roles : []);

    return roles
      .pipe(
        map((roles) => {
          return this.getLowerCaseRoles(roles);
        }),
      );
  }
}
